<template>
  <div class="order_msg" v-if="isSuccess">
    <div class="order_text_area">
      <img src="@/assets/agreement/complete.png">
      <h2 class="order_msg_title order_msg_success">支付成功</h2>
    </div>
    <div class="order_items_area">
      <div class="order_item_area">
        <div class="order_item_hd">支付编号</div>
        <div class="order_item_bd" id="orderNo">{{ orderNo }}</div>
      </div>
      <div class="order_item_area">
        <div class="order_item_hd">支付金额</div>
        <div class="order_item_bd" id="amount">{{ amount }} 元</div>
      </div>
    </div>
    <div class="text-center">
      <van-button class="btn-get" @click="jupout" id="btnSign" v-if="installment == 1">前往代扣签约</van-button>
    </div>
  </div>
  <div v-else class="order_msg">
    <div class="order_text_area">
      <img src="@/assets/agreement/complete.png">
      <h2 class="order_msg_title order_msg_success">支付失败</h2>
    </div>
    <div class="msg">
      <span>订单号：{{ orderNo }}, 服务号：{{ insuranceNo }}, 支付失败！</span><br>
      <span>失败原因：{{ respMag }} 。</span>
    </div>
    <div class="text-center">
      <!-- <van-button class="btn-get" @click="jupout" id="btnSign" v-if="installment == 1">重新支付</van-button> -->
    </div>
  </div>
</template>
<script>
  const result = {
    UNKNOW: '未知异常',
    NOTPAY: '未支付',
    SUCCESS: '支付成功',
    TIMEOUT: '订单超时',
    FAIL: '支付失败',
    REFUND: '转入退款',
    RUSH: '已冲正',
    REVOKED: '已撤销',
    CLOSE: '已关闭'
  } 
  import { payQuery } from '@/api/sign'
  export default {
    data() {
      return {
        result,
        isSuccess: true, // 付款是否成功
        orderNo: '',
        amount: 0,
        insuranceNo: '',
        respMag: '',
        installment: 1, // 是否分期，1-是，0-否
        policySource: 2, // 录单来源，1-PC，2-H5，3-聚销售小程序，默认是H5
        url: '/sign/auth?id='
      }
    },
    async created() {
      const route = this.$route.query
      this.orderNo = route.orderNo
      this.amount = route.amount
      this.installment = route.installment
      this.insuranceNo  = route.insuranceNo
      this.policySource = route.policySource
      if (!this.orderNo) return
      const res = await payQuery({ tradeOrderNo: this.orderNo })
      this.isSuccess = res.success && res.content.status === 'SUCCESS'
      this.respMag = res.respMag || this.result[res.content.status]
     
      if (this.policySource === '3') {
        this.url = '/wechat_pay/bind_card?insuranceNo='
      }
    },
    methods: {
      jupout() {
        let mchData = { action: 'jumpOut', jumpOutUrl: this.$baseH5Url + this.url + this.insuranceNo };
        let postData = JSON.stringify(mchData);
        parent.postMessage(postData, 'https://payapp.weixin.qq.com');
      }
    }
  }
</script>
<style type="text/css" scoped>
  * {
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-touch-callout: none;
    -webkit-appearance: none !important;
    -webkit-tap-highlight-color: transparent;
  }

  html,
  body {
    position: relative;
    max-width: 12.8rem;
    height: 100%;
    min-height: 100%;
    margin: 0rem auto;
    font-size: 0.32rem;
    font-family: -apple-system-font, Helvetica Neue, Helvetica, sans-serif;
    color: #111;
    background-color: #fff;
  }

  .btn-get {
    width: 80%;
    height: 1rem;
    line-height: 1rem;
    -moz-border-radius: 0.06rem;
    border-radius: 0.6rem;
    font-size: 0.4rem;
    color: #f4fff7;
    text-align: center;
    background-color: #39af5a;
    border: none;
    outline: 0;
    letter-spacing: 0.1rem;
  }

  .order_msg {
    padding-top: 2rem;
    text-align: center;
  }

  .order_text_area {
    margin-bottom: 1rem;
    padding: 0 1.3rem;
  }

  .order_text_area img {
    width: 1.6rem;
  }

  .order_msg_title {
    line-height: 1.6;
    margin-bottom: 0.4rem;
    font-weight: bold;
    font-size: 1.4rem;
    color: #098AE1;
    margin-top: 0.2rem;
  }

  .order_msg_success {
    line-height: 1.6;
    font-size: 0.6rem;
    color: #999;
  }

  .order_items_area {
    margin: 0.5rem 1rem;
    padding: 0.5rem 0;
    border-top: 1px solid #eee;
  }

  .order_item_area {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    /* line-height: 93.75px; */
    font-size: 0.5rem;
    text-align: left;
  }

  .order_item_hd {
    width: 3rem;
    color: #666;
    font-size: 0.4rem;
  }

  .order_item_bd {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    color: #333;
    font-size: 0.4rem;
  }

  .text-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-height:0rem) and (max-height:8.98rem) {
    html {
      font-size: 0.18rem;
    }
  }

  @media screen and (min-height:9rem) and (max-height:9.98rem) {
    html {
      font-size: 0.2rem;
    }
  }

  @media screen and (min-height:10rem) and (max-height:10.98rem) {
    html {
      font-size: 0.22rem;
    }
  }

  @media screen and (min-height:11rem) and (max-height:11.98rem) {
    html {
      font-size: 0.24rem;
    }
  }

  @media screen and (min-height:12rem) and (max-height:12.98rem) {
    html {
      font-size: 0.26rem;
    }
  }

  @media screen and (min-height:13rem) and (max-height:13.98rem) {
    html {
      font-size: 0.29rem;
    }
  }

  @media screen and (min-height:14rem) and (max-height:14.98rem) {
    html {
      font-size: 0.32rem;
    }
  }

  @media screen and (min-height:15rem) and (max-height:15.98rem) {
    html {
      font-size: 0.33rem;
    }
  }

  @media screen and (min-height:16rem) and (max-height:16.98rem) {
    html {
      font-size: 0.35rem;
    }
  }

  @media screen and (min-height:17rem) and (max-height:17.98rem) {
    html {
      font-size: 0.37rem;
    }
  }

  @media screen and (min-height:18rem) and (max-height:18.98rem) {
    html {
      font-size: 0.39rem;
    }
  }

  @media screen and (min-height:19rem) and (max-height:19.98rem) {
    html {
      font-size: 0.41rem;
    }
  }

  @media screen and (min-height:20rem) and (max-height:25.6rem) {
    html {
      font-size: 0.42rem;
    }
  }

  @media (min-height:25.62rem) {
    html {
      font-size: 0.44rem;
    }
  }
  .msg {
    font-size: 0.42667rem;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 30px;
  }
</style>
