import request from '@/utils/request'
const signApi = {
  // 实名认证
  save: '/payment/apply/submitInform', // 提交身份证信息
  // 绑定手机号码
  bindMobile: '/payment/apply/bindMobile',
  // 发送短信验证码
  sendSmsCode: '/payment/apply/sendSmsCode',
  // 卡号是否是支持的银行查询
  queryCardBin: '/payment/apply/queryCardBin',
  // 绑定银行卡
  bindBankCard: '/payment/apply/bindBankCard',
  // 银行卡绑定发送短信验证码
  confirmBindBankCard: '/payment/apply/confirmBindBankCard',
  // 验证验证码
  // bindMobile: '/payment/apply/bindMobile',
  // 查询信息接口
  queryStepInform: '/payment/apply/queryStepInform',
  // 银行卡接口
  getSelectBankCode: '/payment/bankCode/getSelect',
  // 银行服务授权协议
  getBankAgreement: '/payment/bankCode/getBankAgreement',
  // 查询保单信息
  queryContractInfo: '/payment/apply/queryContractInfo',
  deployContract: '/payment/apply/deployContract', // 电子签名
  payQuery: '/payment/inside/payQuery', // 查询是否支付成功
  // 查看契约所签约合同
  viewContract: '/payment/apply/viewContract',
  upload: '/basics/upload/material', // 上传附件
  unbindMobile: '/payment/apply/unbindMobile' // 解绑手机号
}

const config = {
  'Content-Type': 'multipart/form-data;charset=UTF-8'
}
export function save(params) {
  return request.post(signApi.save, params, config)
}
export function bindMobile(params) {
  return request.post(signApi.bindMobile, params)
}
export function sendSmsCode(params) {
  return request.post(signApi.sendSmsCode, params)
}
export function queryCardBin(params) {
  return request.post(signApi.queryCardBin, params)
}
export function bindBankCard(params) {
  return request.post(signApi.bindBankCard, params)
}
export function confirmBindBankCard(params) {
  return request.post(signApi.confirmBindBankCard, params)
}
export function queryStepInform(params) {
  return request.get(signApi.queryStepInform, params)
}
export function getSelectBankCode(params) {
  return request.get(signApi.getSelectBankCode, params)
}
export function getBankAgreement(params) {
  return request.post(signApi.getBankAgreement, params)
}
export function queryContractInfo(params) {
  return request.post(signApi.queryContractInfo, params)
}
export function deployContract(params) {
  return request.get(signApi.deployContract, params)
}
export function payQuery(params) {
  return request.get(signApi.payQuery, params)
}
export function viewContract(params) {
  return request.get(signApi.viewContract, params)
}
export function uploadImg(params) {
  return request.post(signApi.upload, params, {
    'Content-Type': "application/x-www-form-urlencoded"
  })
}
export function unbindMobile(params) {
  return request.get(signApi.unbindMobile, params)
}
